<template>
    <div ref="navbar" class="TopToolbar flex_l_c" :style="{background: defaultTheme}">
        <div class="logoBox flex_c_c">
            <img src="~@/assets/img/logo_white.png" class="logo_alone">
            <div class="name" v-if="!sidebarFold">后台系统</div>
        </div>
        <div class="toolbarBox flex_b_c">
            <div class="flex_l_c">
                <el-menu mode="horizontal">
                    <el-menu-item @click="sidebarFold = !sidebarFold">
                        <i :class="sidebarFold ? 'el-icon-s-unfold' : 'el-icon-s-fold'"></i>
                    </el-menu-item>
                </el-menu>
                <el-menu :default-active="topMenuActiveIndex" ref="topMenu" mode="horizontal">
                    <el-menu-item class="el_menu_item" v-for="menu in topMenuList"
                                  :index="menu.id"
                                  :key="menu.id"
                                  @click="showLeftMenu(menu)"
                                  :ref="menu.id"
                                  :menu="menu">
                        <i :class="`${menu.icon}`" style="display: inline-block!important;"></i>
                        {{menu.name}}
                    </el-menu-item>
                    <el-submenu index="2" v-if="topHideMenuList.length != 0">
                        <template slot="title">更多</template>
                        <el-menu-item v-for="menu in topHideMenuList"
                                      :index="menu.id"
                                      :key="menu.id"
                                      :ref="menu.id"
                                      @click="showLeftMenu(menu)"
                                      :menu="menu">
                            <i :class="`${menu.icon}`"
                               style="display: inline-block!important;"></i>
                            {{menu.name}}
                        </el-menu-item>
                    </el-submenu>
                </el-menu>
            </div>
            <el-menu mode="horizontal">
                <el-menu-item>
                    <el-dropdown :show-timeout="0" placement="bottom">
                        <span class="el-dropdown-link">
                            <img v-if="userAvatar == ''" class="touxiang"
                                 src="../../assets/img/head.jpg">
                            <el-avatar v-else size="medium" :src="userAvatar"></el-avatar>
                            <span class="userName">{{ userName }}</span>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item @click.native="personalCenter()">个人中心</el-dropdown-item>
                            <el-dropdown-item @click.native="logoutHandle()">退出</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </el-menu-item>
            </el-menu>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                activeIndex: '',
                topMenuList: [],
                topHideMenuList: [],
                allMenuList: [],
                inputForm: {
                    operName: JSON.parse(sessionStorage.getItem("userInfo")).name,
                    operId: JSON.parse(sessionStorage.getItem('userInfo')).id
                },
                total: 0,
            }
        },
        computed: {
            // 顶部选中菜单
            topMenuActiveIndex: {
                get() {
                    return this.$store.state.common.topMenuActiveIndex
                },
                set(val) {
                    this.$store.commit('common/updateTopMenuActiveIndex', val)
                }
            },
            // 菜单缩放
            sidebarFold: {
                get() {
                    return this.$store.state.common.sidebarFold
                },
                set(val) {
                    this.$store.commit('common/updateSidebarFold', val)
                }
            },
            // 页签
            mainTabs: {
                get() {
                    return this.$store.state.common.mainTabs
                },
                set(val) {
                    this.$store.commit('common/updateMainTabs', val)
                }
            },
            // 主题色
            defaultTheme() {
                return this.$store.state.config.defaultTheme
            },
            // 导航模式
            defaultLayout() {
                return this.$store.state.config.defaultLayout
            },
            sockTypeData() {
                return this.$store.state.common.sockType
            },
            sockPushData() {
                return this.$store.state.common.sockPush
            },
            // 用户名
            userName: {
                get() {
                    return this.$store.state.user.userInfo.name
                }
            },
            // 用户头像
            userAvatar: {
                get() {
                    return this.$store.state.user.userInfo.image
                }
            },
        },
        created() {
            this.allMenuList = JSON.parse(sessionStorage.getItem('allMenuList') || '[]')
            if (this.defaultLayout === 'top') {
                this.topMenuActiveIndex = this.allMenuList[0].id
                this.showLeftMenu(this.allMenuList[0])
            } else {
                this.$store.commit('common/updateLeftMenuList', this.allMenuList)
            }
        },
        mounted() {
            this.getTotal();
            this.resetDocumentClientHeight();
            if (this.defaultLayout === 'top') {
                this.fixTopMenu()
            }
        },
        watch: {
            // 顶部选中菜单
            topMenuActiveIndex(val) {
                this.topMenuList.forEach((menu) => {
                    if (menu.id === val) {
                        this.showLeftMenu(menu)
                    }
                })
                this.topHideMenuList.forEach((menu) => {
                    if (menu.id === val) {
                        this.showLeftMenu(menu)
                    }
                })
            },
            // 导航模式
            defaultLayout() {
                if (this.defaultLayout === 'top') {
                    let needSetLeft = true
                    this.allMenuList.forEach((item) => {
                        if (item.id === this.topMenuActiveIndex) {
                            this.showLeftMenu(item)
                            needSetLeft = false
                        }
                    })
                    if (needSetLeft) {
                        this.topMenuActiveIndex = this.allMenuList[0].id
                        this.showLeftMenu(this.allMenuList[0])
                    }
                    this.fixTopMenu()
                } else {
                    this.topMenuList = []
                    this.topHideMenuList = []
                    this.$store.commit('common/updateLeftMenuCategory', '')
                    this.$store.commit('common/updateLeftMenuList', this.allMenuList)
                }
            },
            sockTypeData() {
                this.getTotal()
            },
            sockPushData(e) {
                console.log("推送消息：", e);
                let that = this;
                let id = JSON.parse(sessionStorage.getItem('userInfo')).id
                e.split(",").forEach(function (v) {
                    if (id == v) {
                        that.getTotal()
                    }
                })
            }
        },
        methods: {
            getTotal() {
                this.$axios(this.api.auth.queryTotal, {}, 'get').then(data => {
                    if (data.status) {
                        this.total = data.data;
                    }
                })
            },
            // 个人中心
            personalCenter() {
                this.$emit('showPersonal', true);
            },
            // 通知
            toNotifyList() {
                this.$router.push('/notify/MyNotifyList')
            },
            // 退出
            logoutHandle() {
                let id = JSON.parse(sessionStorage.getItem('userInfo')).id
                this.$confirm(`确定进行[退出]操作?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    sessionStorage.clear();
                    this.$router.replace({name: 'login'});
                    window.location.reload();
                })
            },
            fixTopMenu() {
                let width = window.getComputedStyle(this.$refs.navbar).width
                let size = (parseInt(width) - 700) / 124
                this.topMenuList = []
                this.topHideMenuList = []
                this.allMenuList.forEach((item, index) => {
                    if (index < size - 2) {
                        this.topMenuList.push(item)
                    } else {
                        this.topHideMenuList.push(item)
                    }
                })
            },
            // 重置窗口可视高度
            resetDocumentClientHeight() {
                window.onresize = () => {
                    if (this.$refs.navbar) {
                        if (this.defaultLayout === 'top') {
                            this.fixTopMenu()
                        }
                    }
                }
            },
            showRight() {
                this.$emit('showRight', true)
            },
            showLeftMenu(menu) {
                if (menu.permission == "link") {
                    window.open(menu.href);
                    return
                }
                this.$store.commit('common/updateLeftMenuList', menu.children)
                this.$store.commit('common/updateLeftMenuCategory', menu.name)
                if(menu.children[0]) {
                    this.$router.push({path: menu.children[0].href})
                } else {
                    this.$router.push({path: menu.href})
                }
            },
        }
    }
</script>

<style scoped>
    .userName {
        color: #ffffff;
        padding-left: 10px;
        font-size: 14px;
    }

    .el-badge {
        display: inline !important;
    }
</style>
